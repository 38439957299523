import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export async function login(email, password) {
  const postData = {
    email,
    password,
  };

  return await axios.post(
    `http://209.126.2.133:5000/admin/auth/login`,
    postData
  );
}

export function formatError(errorMessage) {
  console.log(errorMessage, "errormasg");
  if (errorMessage) {
    swal("Oops", errorMessage, "error");
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("handy_token", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("handy_token");
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  let tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}
export function isLogin() {
  const tokenDetailsString = localStorage.getItem("handy_token");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}
