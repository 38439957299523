import { useState } from "react";
import axios from "axios";

const useImageUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const uploadImage = async (file) => {
    if (!file) {
      setError("No file provided.");
      return null; // Explicitly return null
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append("image", file);

    try {
      const result = await axios.post(
        "http://209.126.2.133:5000/user/pofile/uploadImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            const percentCompleted = Math.round(
              (event.loaded * 100) / event.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      setResponse(result?.data?.data);
      setProgress(100);
      return result?.data?.data; // Return the response directly
    } catch (err) {
      setError(err.response?.data?.message || "Failed to upload image.");
      return null; // Return null on error
    } finally {
      setUploading(false);
    }
  };

  return { uploadImage, uploading, progress, response, error };
};

export default useImageUpload;
