import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  getCategoryApi,
  addServiceApi,
  actionCategoryApi,
  deleteCategoryApi,
  getServiceApi,
  editServiceApi,
  getServiceProviderApi,
  approveProviderApi,
  makeServiceToManagerApi,
  assignRoleApi,
} from "../components/APIs/Api";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaultDp.jpg";
import animationData from "../../images/loding.json";

import moment from "moment";
import Filter from "../components/Filter/Filter";
import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";
import S3Image from "../components/S3Image";
import CustomTooltip from "../components/Tooltip";
import { useNavigate } from "react-router-dom";
import AddCategoryModal from "../modal/AddCategoryModal";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import useImageUpload from "../components/useImageUpload";
import RejectMessageModal from "../modal/RejectMessageModal";
import AddBadgeModal from "../modal/AddBadgeModal";

export default function ServiceProviderManagement() {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isBadgeModal, setIsBadgeModal] = useState(false);

  const [isSuspendedModal, setIsSuspendedModal] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      const res = await getServiceProviderApi(
        currentPage,
        itemsPerPage,
        search,
        false
      );
      setUsersList(res);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  async function handleAssignRole(id) {
    setLoader(true);
    try {
      const response = await makeServiceToManagerApi(id);
      toast.success("Role Assigned successfully");
      tableData();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  //=========pagination=============
  const totalItems = usersList?.data?.data?.count;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    tableData();
    scrollToTop();
  }, [search, currentPage]);

  return (
    <>
      <div>
        <PageTitle activeMenu="Service List" motherMenu="Service Providers " />
        <div className="pb-4  w-100">
          <div style={{ width: "40%" }}>
            <Filter searchType="search" setSearch={handleSearch} />
          </div>
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>

                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>email</strong>
                      </th>
                      <th>
                        <strong>phone</strong>
                      </th>

                      <th>
                        <strong>Created at</strong>
                      </th>

                      <th className="text-center">
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.users?.map((item, i) => (
                      <tr key={item?._id}>
                        <td>
                          <img
                            src={`http://209.126.2.133:5000${item?.image}`}
                            className="profile-img"
                            alt="Profile"
                          />
                        </td>

                        <td>
                          {item?.name?.length > 15
                            ? item?.name.slice(0, 15) + "..."
                            : item?.name || ""}
                        </td>
                        <td>{item?.email}</td>
                        <td>
                          {item?.countryCode} {item?.phoneNumber}
                        </td>

                        <td>{moment(item?.createdAt).format("ll")}</td>

                        <td className="text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                              disabled={item?.isDeleted ? true : false}
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate("/service-details", {
                                    state: item?._id,
                                  });
                                }}
                              >
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedCategory(item);
                                  setIsBadgeModal(true);
                                }}
                              >
                                Assign badges
                              </Dropdown.Item>
                              {!item?.isAgency && (
                                <Dropdown.Item
                                  onClick={() => {
                                    handleAssignRole(item?._id);
                                  }}
                                >
                                  Assign as service Manager
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.count === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.count !== 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total{" "}
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {totalItems}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {/* {deleteCategoryModal && (
        <ConfirmationModal
          show={deleteCategoryModal}
          onHide={() => setDeleteCategoryModal(false)}
          content={"Are you sure you want to Delete this Category ?"}
          api={deleteCategoryApi}
          data={selectedCategory}
          refresh={tableData}
          successMessage={"Category Deleted Successfully"}
        />
      )} */}
      {/* {addCategoryModal && (
        <AddCategoryModal
          show={addCategoryModal}
          onHide={() => setAddCategoryModal(false)}
          api={addServiceApi}
          refresh={tableData}
          title={"Add Service"}
          successMessage={"Add Service Successfully"}
          buttonTitle={"Add"}
        />
      )} */}
      {/* {editCategoryModal && (
        <AddCategoryModal
          show={editCategoryModal}
          onHide={() => setEditCategoryModal(false)}
          api={editServiceApi}
          refresh={tableData}
          title={"Update Service"}
          successMessage={"Updated Service Successfully"}
          buttonTitle={"Update"}
          selectedCategory={selectedCategory}
        />
      )} */}
      {isBadgeModal && (
        <AddBadgeModal
          show={isBadgeModal}
          handleClose={() => setIsBadgeModal(false)}
          fetchBadges={tableData}
          id={selectedCategory?._id}
        />
      )}
    </>
  );
}
