import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { assignRoleApi } from "../components/APIs/Api";

const AssignUserRoleModal = ({ show, onHide, userId, fetchUsers }) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch roles from API
  //   useEffect(() => {
  //     if (show) {
  //       fetchRoles();
  //     }
  //   }, [show]);

  //   const fetchRoles = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get("/api/roles");
  //       setRoles(response.data);
  //     } catch (error) {
  //       toast.error("Failed to fetch roles.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const handleSubmit = async () => {
    if (!selectedRole) {
      toast.error("Please select a role.");
      return;
    }

    setIsSubmitting(true);
    try {
      await assignRoleApi(userId, selectedRole);
      toast.success("Role assigned successfully!");
      fetchUsers();
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign User Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <Form>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select Role . . .</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || loading}
        >
          {isSubmitting ? <Spinner size="sm" animation="border" /> : "Assign"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignUserRoleModal;
