import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./UserDetails.css";
import toast from "react-hot-toast";
import {
  getUserDetailsApi,
  rejectServiceProviderApi,
} from "../components/APIs/Api";
import pdfIcon from "../../icons/pdf.png";
import moment from "moment";
import defaultImage from "../../images/defaultDp.jpg";
import { Card, Spinner, Table } from "react-bootstrap";
import XGourdLoader from "../components/XGourdLoader";

function ServiceDetails() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("Payment-details");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const serviceId = location.state;
  const onImageError = (e) => {
    e.target.src = defaultImage;
  };
  console.log(activeTab);

  async function tableData() {
    setLoader(true);
    try {
      const res = await getUserDetailsApi(serviceId);
      setData(res?.data?.data);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  const getFormattedDays = (days) => {
    return days
      ?.map((day) => day?.charAt(0)?.toUpperCase() + day?.slice(1)) // Capitalize first letter
      ?.join(", "); // Join the days with a comma and space
  };

  async function handleRejectVender(id) {
    setIsLoader(true);
    const postData = {
      userId: id,
      userType: "user",
      reason: "",
    };
    try {
      const response = await rejectServiceProviderApi(postData);
      if (data?.isSuspended) {
        toast.success("User Active successfully");
      } else {
        toast.success("User Inactive successfully");
      }
      tableData();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setIsLoader(false);
    }
  }
  const formattedDays = getFormattedDays(data?.availability?.excludeDay);

  useEffect(() => {
    tableData();
  }, []);
  return (
    <>
      {loader ? (
        <div className="text-center mt-5">
          <XGourdLoader />
        </div>
      ) : (
        <div>
          <div className="user-header">
            <div>
              <img
                src={`http://209.126.2.133:5000${data?.image}`}
                className="user-image"
              />
            </div>
            <div className="user-info ">
              <div className="d-flex justify-content-between">
                <h2>{data?.name}</h2>
                <div>
                  {data?.isSuspended ? (
                    <button
                      className="btn btn-sm btn-success"
                      disabled={isLoader}
                      onClick={() => handleRejectVender(data?._id)}
                    >
                      {isLoader ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Make Active"
                      )}
                    </button>
                  ) : (
                    <button
                      disabled={isLoader}
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRejectVender(data?._id)}
                    >
                      {isLoader ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Make Inactive"
                      )}
                    </button>
                  )}
                </div>
              </div>
              <p className="user-job-title">{data?.userType}</p>
              <p className="user-location m-0">{data?.email}</p>
              <p className="user-location m-0">
                {data?.countryCode} {data?.phoneNumber}
              </p>

              <div className="user-actions mt-0">
                <p className="m-0">
                  <strong>Status :</strong>{" "}
                  <span>
                    {" "}
                    {data?.isSuspended ? (
                      <span class="badge-danger light badge">Inactive</span>
                    ) : (
                      <span class="badge-success light badge">Active</span>
                    )}
                  </span>
                </p>
                <p>
                  <strong>Created At: </strong>
                  <span>
                    {" "}
                    {moment(data?.availability?.endDate).format("ll")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="profile-tabs">
              <p
                className={`tab-button ${
                  activeTab === "Payment-details" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Payment-details")}
              >
                Payment details
              </p>
              <p
                className={`tab-button ${
                  activeTab === "Services" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Services")}
              >
                Services History
              </p>
            </div>
            <div className="tab-content">
              {activeTab === "Payment-details" ? (
                <div className="user-contact">
                  <div className="d-flex justify-content-center">
                    <h5>Data Not found !</h5>
                  </div>
                </div>
              ) : activeTab === "Services" ? (
                <div className="user-contact">
                  <div className="d-flex justify-content-center">
                    <h5>Data Not found !</h5>
                  </div>
                </div>
              ) : (
                <Card className="mt-3">
                  <Card.Body>
                    <div className="text-black">
                      {data?.subservices ? (
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <strong>Icon</strong>
                              </th>
                              <th>
                                <strong>NAME</strong>
                              </th>

                              <th>
                                <strong>Created At</strong>
                              </th>

                              <th className="text-center">
                                <strong>price</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.subservices?.map((winery) => (
                              <tr
                                className={
                                  winery.isDeleted ? "disabled-row" : ""
                                }
                              >
                                <td>
                                  <img
                                    src={`http://209.126.2.133:5000/${winery?.subservice?.icon}`}
                                    height={50}
                                    width={50}
                                    style={{ borderRadius: "25px" }}
                                  />
                                </td>
                                <td>{winery?.subservice?.name}</td>
                                <td>
                                  {moment(winery?.subservice?.createdAt).format(
                                    "ll"
                                  )}
                                </td>

                                <td className="text-center">€{winery.price}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="p-3 d-flex justify-content-center">
                          <h4>No results</h4>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServiceDetails;
