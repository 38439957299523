import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  getCategoryApi,
  addServiceApi,
  actionCategoryApi,
  deleteCategoryApi,
  getServiceApi,
  editServiceApi,
} from "../components/APIs/Api";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaultDp.jpg";
import animationData from "../../images/loding.json";

import moment from "moment";
import Filter from "../components/Filter/Filter";
import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";
import S3Image from "../components/S3Image";
import CustomTooltip from "../components/Tooltip";
import { useNavigate } from "react-router-dom";
import AddCategoryModal from "../modal/AddCategoryModal";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import useImageUpload from "../components/useImageUpload";

export default function ServiceManagement() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      const res = await getServiceApi(currentPage, itemsPerPage, search);
      setUsersList(res);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  //=========pagination=============
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    tableData();
    scrollToTop();
  }, []);

  return (
    <>
      <div>
        <PageTitle activeMenu="Service List" motherMenu="Service Management" />
        <div className="pb-4 d-flex justify-content-end w-100">
          {/* <div style={{ width: "40%" }}>
            <Filter searchType="search" setSearch={handleSearch} />
          </div> */}
          <div>
            <button
              className="btn btn-primary btn"
              onClick={() => setAddCategoryModal(true)}
            >
              Add Service
            </button>
          </div>
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>

                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th className="text-center ">
                        <strong>Sub services</strong>
                      </th>
                      <th>
                        <strong>Created at</strong>
                      </th>

                      <th className="text-center">
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item?.documents?._id}>
                        <td>
                          <img
                            src={`http://209.126.2.133:5000${item?.icon}`}
                            className="profile-img"
                            alt="Profile"
                          />
                        </td>

                        <td>
                          {item?.name?.length > 15
                            ? item?.name.slice(0, 15) + "..."
                            : item?.name || ""}
                        </td>
                        <td className="text-center ">
                          {item?.subservicesCount}
                        </td>

                        <td>{moment(item?.createdAt).format("ll")}</td>

                        <td className="text-center">
                          <div className="display-flex gap">
                            {/* <CustomTooltip content={"Delete"}>
                              <div
                                class="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setSelectedCategory(item);
                                  setDeleteCategoryModal(true);
                                }}
                              >
                                <i class="fa fa-trash"></i>
                              </div>
                            </CustomTooltip> */}
                            <CustomTooltip content={"Edit"}>
                              <div
                                class="btn  btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "#2f4cdd",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setSelectedCategory(item);
                                  setEditCategoryModal(true);
                                }}
                              >
                                <i class="fas fa-pencil-alt"></i>
                              </div>
                            </CustomTooltip>
                            <CustomTooltip content={"Sub Services"}>
                              <div
                                class="btn  btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "#2f4cdd",
                                  color: "white",
                                }}
                                onClick={() =>
                                  navigate("/sub-service-management", {
                                    state: item,
                                  })
                                }
                              >
                                <i class="fas fa-eye"></i>
                              </div>
                            </CustomTooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {/* {usersList?.length !== 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total categories{" "}
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {totalItems}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )} */}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {/* {deleteCategoryModal && (
        <ConfirmationModal
          show={deleteCategoryModal}
          onHide={() => setDeleteCategoryModal(false)}
          content={"Are you sure you want to Delete this Category ?"}
          api={deleteCategoryApi}
          data={selectedCategory}
          refresh={tableData}
          successMessage={"Category Deleted Successfully"}
        />
      )} */}
      {addCategoryModal && (
        <AddCategoryModal
          show={addCategoryModal}
          onHide={() => setAddCategoryModal(false)}
          api={addServiceApi}
          refresh={tableData}
          title={"Add Service"}
          successMessage={"Add Service Successfully"}
          buttonTitle={"Add"}
        />
      )}
      {editCategoryModal && (
        <AddCategoryModal
          show={editCategoryModal}
          onHide={() => setEditCategoryModal(false)}
          api={editServiceApi}
          refresh={tableData}
          title={"Update Service"}
          successMessage={"Updated Service Successfully"}
          buttonTitle={"Update"}
          selectedCategory={selectedCategory}
        />
      )}
    </>
  );
}
