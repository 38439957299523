import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ServiceInfo from "./ServiceInfo";
import ProfileCard from "../components/ui/ProfileCard";
import toast from "react-hot-toast";
import {
  approveProviderApi,
  getMemberApi,
  getServiceProviderDetailsApi,
} from "../components/APIs/Api";
import S3Image from "../components/S3Image";
import pdfIcon from "../../icons/pdf.png";
import moment from "moment";
import defaultImage from "../../images/defaultDp.jpg";
import { Card, Spinner, Table } from "react-bootstrap";
import XGourdLoader from "../components/XGourdLoader";
import RejectMessageModal from "../modal/RejectMessageModal";
import RejectServiceProviderModal from "../modal/RejectServiceProviderModal";

function ServiceDetails() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isSuspendedModal, setIsSuspendedModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);

  const [activeTab, setActiveTab] = useState("Documents");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const serviceId = location.state;
  const onImageError = (e) => {
    e.target.src = defaultImage;
  };

  async function tableData() {
    setLoader(true);
    try {
      const res = await getServiceProviderDetailsApi(serviceId);
      setData(res?.data?.data);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  async function fetchMembers() {
    setIsLoading(true);
    try {
      const res = await getMemberApi(serviceId);
      setMembers(res?.data?.data);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleApprove(id) {
    setIsLoader(true);
    try {
      const response = await approveProviderApi(id);
      if (data?.isApprovedByAdmin) {
        toast.success("Request Rejected successfully");
      } else {
        toast.success("Request Approve successfully");
      }
      tableData();
      console.log(response);
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    if (data?.isAgency) {
      fetchMembers();
    }
    tableData();
  }, []);
  useEffect(() => {
    if (activeTab === "members") {
      fetchMembers();
    }
  }, [activeTab === "members"]);
  return (
    <>
      {loader ? (
        <XGourdLoader />
      ) : (
        <div>
          <div className="user-header">
            <div>
              <img
                src={`http://209.126.2.133:5000${data?.image}`}
                className="user-image"
              />
            </div>
            <div className="user-info ">
              <div className="d-flex justify-content-between">
                <h2>{data?.name}</h2>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  {data?.isSuspended ? (
                    <button
                      className="btn btn-sm btn-success "
                      disabled={isLoader}
                      onClick={() => setIsSuspendedModal(true)}
                    >
                      Make Active
                    </button>
                  ) : (
                    <button
                      disabled={isLoader}
                      className="btn btn-sm btn-danger"
                      onClick={() => setIsSuspendedModal(true)}
                    >
                      Make Suspend
                    </button>
                  )}
                  {data?.isApprovedByAdmin ? (
                    <button
                      className="btn btn-sm btn-danger "
                      onClick={() => handleApprove(data?._id)}
                    >
                      {isLoader ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Reject Request"
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-success "
                      onClick={() => handleApprove(data?._id)}
                    >
                      {isLoader ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Approve Request"
                      )}
                    </button>
                  )}
                </div>
              </div>
              <p className="user-job-title">{data?.userType}</p>
              <p className="user-location m-0">{data?.email}</p>
              <p className="user-location m-0">
                {data?.countryCode} {data?.phoneNumber}
              </p>

              <div className="user-actions mt-0">
                <p className="m-0">
                  <strong>Profile status :</strong>{" "}
                  <span>
                    {data?.isApprovedByAdmin ? (
                      <span class="badge-success light badge">Approve</span>
                    ) : (
                      <span class="badge-danger light badge">Rejected</span>
                    )}
                  </span>
                </p>
                {data?.badge && (
                  <div className="section-first mt-2">
                    <h4>Badges</h4>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "8px" }}
                    >
                      {data?.badges?.map((badge) => (
                        <p className="service-badge">{badge}</p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="profile-tabs">
              <p
                className={`tab-button ${
                  activeTab === "Documents" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Documents")}
              >
                Documents
              </p>
              <p
                className={`tab-button ${
                  activeTab === "Services" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Services")}
              >
                Services
              </p>
              <p
                className={`tab-button ${
                  activeTab === "Ratings" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Ratings")}
              >
                Ratings
              </p>
              <p
                className={`tab-button ${
                  activeTab === "past-job" ? "active" : ""
                }`}
                onClick={() => handleTabClick("past-job")}
              >
                Past jobs
              </p>
              {data?.isAgency && (
                <p
                  className={`tab-button ${
                    activeTab === "members" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("members")}
                >
                  Members
                </p>
              )}
            </div>
            <div className="tab-content">
              {activeTab === "Documents" ? (
                <div className="document-section">
                  <h5>Certifications</h5>
                  <div className="documents-images">
                    <div className="d-flex flex-column align-items-center">
                      {data?.certifications?.map((item, index) => (
                        <div key={index}>
                          {console.log(item)}
                          {item.endsWith(".pdf") ? (
                            <a
                              href={`http://209.126.2.133:5000/${encodeURIComponent(
                                item
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="pointer p-2"
                                src={pdfIcon}
                                alt="PDF Icon"
                                height={50}
                                width={50}
                              />
                            </a>
                          ) : (
                            <img
                              src={`http://209.126.2.133:5000/${encodeURIComponent(
                                item
                              )}`}
                              alt="Preview"
                              height={40}
                              width={50}
                              className="pointer"
                              onError={onImageError}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <h5>Previous Work</h5>
                  <div className="d-flex align-items-start documents-images">
                    {data?.prevoiusWork?.map((item) => (
                      <div>
                        {item.endsWith(".pdf") ? (
                          <a
                            href={`http://209.126.2.133:5000/${encodeURIComponent(
                              item
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="pointer p-2"
                              src={pdfIcon}
                              alt="PDF Icon"
                              height={50}
                              width={50}
                            />
                          </a>
                        ) : (
                          <img
                            src={`http://209.126.2.133:5000/${encodeURIComponent(
                              item
                            )}`}
                            alt="Preview"
                            height={40}
                            width={50}
                            className="pointer"
                            onError={onImageError}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : activeTab === "Services" ? (
                <Card className="mt-3">
                  <Card.Body>
                    <div className="text-black">
                      {data?.subservices ? (
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <strong>Icon</strong>
                              </th>
                              <th>
                                <strong>NAME</strong>
                              </th>

                              <th>
                                <strong>Created At</strong>
                              </th>

                              <th className="text-center">
                                <strong>price</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.subservices?.map((winery) => (
                              <tr
                                className={
                                  winery.isDeleted ? "disabled-row" : ""
                                }
                              >
                                <td>
                                  <img
                                    src={`http://209.126.2.133:5000/${winery?.subservice?.icon}`}
                                    height={50}
                                    width={50}
                                    style={{ borderRadius: "25px" }}
                                  />
                                </td>
                                <td>{winery?.subservice?.name}</td>
                                <td>
                                  {moment(winery?.subservice?.createdAt).format(
                                    "ll"
                                  )}
                                </td>

                                <td className="text-center">€{winery.price}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="p-3 d-flex justify-content-center">
                          <h4>No results</h4>
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  </Card.Body>
                </Card>
              ) : activeTab === "Ratings" ? (
                <div className="user-contact">
                  <div className="d-flex justify-content-center">
                    <h5>Data Not found !</h5>
                  </div>
                </div>
              ) : activeTab === "members" ? (
                <Card className="mt-3">
                  {isLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center w-100"
                      style={{ height: "300px" }}
                    >
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <Card.Body>
                      <div className="text-black">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <strong>image</strong>
                              </th>
                              <th>
                                <strong>NAME</strong>
                              </th>

                              <th>
                                <strong>email</strong>
                              </th>

                              <th>
                                <strong>phone</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {members?.map((winery) => (
                              <tr>
                                <td>
                                  <img
                                    src={`http://209.126.2.133:5000/${winery?.image}`}
                                    height={50}
                                    width={50}
                                    style={{ borderRadius: "25px" }}
                                  />
                                </td>
                                <td>{winery?.name}</td>
                                <td>{winery?.email}</td>

                                <td>
                                  {winery?.countryCode} {winery?.phoneNumber}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {members?.length === 0 && (
                          <div className="p-3 d-flex justify-content-center">
                            <h4>No results</h4>
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    </Card.Body>
                  )}
                </Card>
              ) : (
                <div className="user-contact">
                  <div className="d-flex justify-content-center">
                    <h5>Data Not found !</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isSuspendedModal && (
        <RejectServiceProviderModal
          show={isSuspendedModal}
          onHide={() => setIsSuspendedModal(false)}
          refresh={tableData}
          data={data?._id}
        />
      )}
    </>
  );
}

export default ServiceDetails;
