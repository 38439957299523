import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import logoTextName from "../../images/logoTextName.svg";

function Login(props) {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  async function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!emailRegex.test(email)) {
      errorObj.email = "Please enter a valid email address.";
      error = true;
    }

    if (!passwordRegex.test(password)) {
      errorObj.password =
        "Password must be at least 8 characters, with at least one uppercase letter, one lowercase letter, one number, and one special character.";
      error = true;
    }

    setErrors(errorObj);

    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, nav, setLoader));
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Remove email error when the user starts typing
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // Remove password error when the user starts typing
    setErrors((prev) => ({ ...prev, password: "" }));
  };

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">Welcome to On Demand Admin</h3>
                <p>Sign in by entering information below</p>
              </div>
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              <form onSubmit={onLogin}>
                <div className="form-group">
                  <label className="mb-2">
                    <strong>Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    placeholder="username"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Password"
                    />
                    {/* <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      className="password-icon"
                      onClick={handleTogglePassword}
                    /> */}
                    <div
                      className="password-icon"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <i className="flaticon-381-view-2"></i>
                      ) : (
                        <i className="flaticon-381-hide"></i>
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    className={`btn btn-block btn-primary ${
                      loader ? "disabled" : ""
                    }`}
                    disabled={loader}
                  >
                    {loader ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ gap: "5px" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </div>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            <div className="inner-content align-self-center">
              <div className="login-logo text-center">
                <img src={logoTextName} alt="" className="logo-text ml-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Login);
