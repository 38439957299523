import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Table, Badge, Dropdown } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  userManagementList,
  rejectServiceProviderApi,
  assignRoleApi,
} from "../components/APIs/Api";

import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Filter from "../components/Filter/Filter";

import { scrollToTop } from "../components/Utils/ScrollOnTop";
import XGourdLoader from "../components/XGourdLoader";
import AssignUserRoleModal from "../modal/AssignRoalModal";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};
const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);
export default function UserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [rejectConfirmationModal, setRejectConfirmationModal] = useState(false);
  const [filters, setFilters] = useState({ userType: "", kycType: "" });
  const [selectedActionItems, setSelectedActionItems] = useState([]);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState("active");

  const navigate = useNavigate();

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  const fetchUserList = async () => {
    setLoader(true);
    try {
      const res = await userManagementList(currentPage, itemsPerPage, search);
      setUsersList(res);
    } catch (err) {
      toast.error(err?.response?.data?.data || err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedActionItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allItemIds = usersList?.data?.data?.map((item) => item._id) || [];

    if (selectedActionItems.length === allItemIds.length) {
      setSelectedActionItems([]);
    } else {
      setSelectedActionItems(allItemIds);
    }
  };

  async function handleAssignRole(id) {
    setLoader(true);
    try {
      const response = await assignRoleApi(id);
      toast.success("Role Assigned successfully");
      fetchUserList();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  function onSendItem(item) {
    navigate("/user-details", { state: item?._id });
  }

  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    fetchUserList();
  }, [currentPage, search, filterTypes]);

  useEffect(() => {
    if (itemsPerPage * currentPage !== totalItems) {
      setCurrentPage(0);
    }
    fetchUserList();
    scrollToTop();
  }, [filters]);

  return (
    <>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <div className="pb-4 d-flex justify-content-between w-100">
        <div style={{ width: "40%" }}>
          <Filter searchType="search" setSearch={handleSearch} />
        </div>
      </div>

      <Col>
        {loader ? (
          <div className="text-center mt-5">
            <XGourdLoader />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>IMAGE</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>Status</th>

                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList?.data?.data?.users?.map((item) => (
                    <tr
                      key={item?._id}
                      className={item.isDeleted ? "disabled-row " : " "}
                    >
                      <td onClick={() => onSendItem(item)}>
                        <img
                          src={`http://209.126.2.133:5000${item?.image}`}
                          className="profile-img"
                        />
                      </td>
                      <td onClick={() => onSendItem(item)}>
                        {item?.name?.length > 15
                          ? `${item?.name.slice(0, 15)}...`
                          : item?.name || ""}
                      </td>
                      <td onClick={() => onSendItem(item)}>
                        {item?.email?.length > 20
                          ? `${item?.email.slice(0, 20)}...`
                          : item?.email}
                      </td>
                      <td onClick={() => onSendItem(item)}>
                        {item?.countryCode} {item?.phoneNumber}
                      </td>

                      <td>
                        {/* <Switch
                          onChange={() => handleRejectVender(item?._id)}
                          checked={item.isSuspended}
                          onColor={"#FF0000"}
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        /> */}
                        {item?.isSuspended ? (
                          <span class="badge-danger light badge">Inactive</span>
                        ) : (
                          <span class="badge-success light badge">Active</span>
                        )}
                      </td>
                      <td className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                            disabled={item?.isDeleted ? true : false}
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/user-details", {
                                  state: item?._id,
                                });
                              }}
                            >
                              Details
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleAssignRole(item?._id)}
                            >
                              Assign as service provider
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {usersList?.data?.totalRecords === 0 && (
                <div className="text-center my-5">Sorry, Data Not Found!</div>
              )}
              {usersList?.data?.totalRecords > 0 && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Total Users{" "}
                    <span className="font-weight-bold">{totalItems} </span>
                  </div>
                  <Pagination
                    pageCount={totalPages}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {isAssignModal && (
        <AssignUserRoleModal
          show={isAssignModal}
          onHide={() => setIsAssignModal(false)}
          fetchUserList={fetchUserList}
          userId={selectedUsers?._id}
        />
      )}
    </>
  );
}
