import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get("/admin/dashboard", {});
  return response;
}

export async function changePasswordApi(postData) {
  const response = await axiosInstance.put(
    `/admin/auth/changePassword`,
    postData
  );
  return response;
}

export async function dashboardGraphApi() {
  const response = await axiosInstance.get("/admin/graph", {});
  return response;
}
export async function userManagementList(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get(
    `/admin/user/list?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}
export async function getUserDetailsApi(id) {
  const response = await axiosInstance.get(
    `/admin/user/detail?userId=${id}`,
    {}
  );
  return response;
}
export async function getMemberApi(id) {
  const response = await axiosInstance.get(
    `/admin/serviceProvider/manager/members?managerId=${id}`,
    {}
  );
  return response;
}

export async function blockUserApi(_id, type) {
  const postData = { ids: _id, type: type };
  const response = await axiosInstance.put(
    `/admin/enableDisableUser`,
    postData
  );
  return response;
}
export async function assignRoleApi(_id) {
  const postData = { userId: _id };
  const response = await axiosInstance.put(
    `/admin/user/convertIntoServiceProvider`,
    postData
  );
  return response;
}
export async function makeServiceToManagerApi(_id) {
  const postData = { serviceProviderId: _id };
  const response = await axiosInstance.post(
    `/admin/serviceProvider/manager`,
    postData
  );
  return response;
}
export async function addManagerMember(serviceProviderId, memberId) {
  const postData = {
    serviceProviderId: serviceProviderId,
    managerId: memberId,
  };
  const response = await axiosInstance.post(
    `/admin/serviceProvider/manager/addServiceProvider`,
    postData
  );
  return response;
}
export async function addBadgeApi(_id, badge) {
  const postData = { serviceProviderId: _id, badge };
  const response = await axiosInstance.post(
    `/admin/serviceProvider/badge`,
    postData
  );
  return response;
}
export async function userKycApi(postData) {
  const response = await axiosInstance.put(`/admin/kycApproveReject`, postData);
  return response;
}

export async function deleteUserApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteUser?_id=${data?._id}`
  );
  return response;
}

// Category Management

export async function getServiceProviderApi(page, limit, search, isManager) {
  const response = await axiosInstance.get(
    `/admin/serviceProvider/list?page=${page}&limit=${limit}&search=${search}&isManager=${isManager}`,
    {}
  );
  return response;
}
export async function getServiceProviderDetailsApi(id) {
  const response = await axiosInstance.get(
    `/admin/serviceProvider/detail?serviceProviderId=${id}`,
    {}
  );
  return response;
}

export async function rejectServiceProviderApi(data) {
  const response = await axiosInstance.put(
    `/admin/serviceProvider/toggleSuspendAccount`,
    data,
    {}
  );
  return response;
}
export async function approveProviderApi(id) {
  console.log(id);
  const data = { serviceProviderId: id };
  const response = await axiosInstance.put(
    `/admin/serviceProvider/profile/status`,
    data
  );
  return response;
}
export async function getServiceApi() {
  const response = await axiosInstance.get(`/admin/service`, {});
  return response;
}
export async function getSubServiceApi(id) {
  const response = await axiosInstance.get(
    `/admin/subservice?serviceId=${id}`,
    {}
  );
  return response;
}
export async function getFeeListApi(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get(
    `/admin/feeList?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}
export async function getFeeHistoryApi(currentPage, itemsPerPage, name) {
  const response = await axiosInstance.get(
    `/admin/history?limit=${itemsPerPage}&page=${currentPage}&name=${name}`,
    {}
  );
  return response;
}
export async function getFeeCountApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `/admin/usersList?limit=${itemsPerPage}&page=${currentPage}`,
    {}
  );
  return response;
}
export async function getBookingListApi(currentPage, itemsPerPage, type) {
  const response = await axiosInstance.get(
    `/admin/bookingList?limit=${itemsPerPage}&page=${currentPage}&status=${type}`,
    {}
  );
  return response;
}
export async function getRatingApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `/admin/reviews?limit=${itemsPerPage}&page=${currentPage}`,
    {}
  );
  return response;
}
export async function addServiceApi(data) {
  const response = await axiosInstance.post("/admin/service", data, {});
  return response;
}
export async function addSubServiceApi(data) {
  const response = await axiosInstance.post("/admin/subservice", data, {});
  return response;
}
export async function uploadImageApi(data) {
  console.log(data);
  const response = await axiosInstance.post("/user/pofile/uploadImage", data);
  return response;
}
export async function editServiceApi(data) {
  const postData = { name: data.name, icon: data.icon, serviceId: data?._id };
  const response = await axiosInstance.put(`/admin/service`, postData, {});
  return response;
}
export async function editSubServiceApi(data) {
  const postData = {
    name: data.name,
    icon: data.icon,
    subserviceId: data?.subserviceId,
  };
  const response = await axiosInstance.put(`/admin/subservice`, postData, {});
  return response;
}

export async function editFeeApi(value, id) {
  const postData = { value };
  const response = await axiosInstance.put(
    `/admin/editFee?_id=${id}`,
    postData
  );
  return response;
}
export async function sendNotificationApi(formData) {
  const postData = { message: formData.message, title: formData.title };
  const response = await axiosInstance.post(
    `/admin/notification?userType=${formData.type}`,
    postData
  );
  return response;
}
export async function deleteCategoryApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteCategory?_id=${data?._id}`
  );
  return response;
}

export async function deleteReviewApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteReview?_id=${data?._id}`
  );
  return response;
}
