import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-hot-toast";
import { addBadgeApi } from "../components/APIs/Api";

const AddBadgeModal = ({ show, handleClose, id, fetchBadges }) => {
  const [badge, setBadge] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!badge.trim()) {
      toast.error("Badge name is required!");
      return;
    }

    setLoading(true);

    try {
      await addBadgeApi(id, badge);
      toast.success("Badge added successfully!");
      setBadge("");
      handleClose();
      fetchBadges();
    } catch (error) {
      console.error("Error adding badge:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign Badge</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="badgeSelect">
            <Form.Label>Select Badge</Form.Label>
            <Form.Control
              as="select"
              value={badge}
              onChange={(e) => setBadge(e.target.value)}
              required
            >
              <option value="">-- Select a Badge --</option>
              <option value="brooch">Brooch</option>
              <option value="silver">Silver</option>
              <option value="gold">Gold</option>
              <option value="platinum">Platinum</option>
              <option value="diamond">Diamond</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? "Saving..." : "Add Badge"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddBadgeModal;
