import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

import toast from "react-hot-toast";
import { rejectServiceProviderApi } from "../components/APIs/Api";

export default function RejectServiceProviderModal({
  show,
  onHide,
  data,
  refresh,
}) {
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [loader, setLoader] = useState(false);

  async function handleRejectVender() {
    if (message.trim() === "") {
      setMessageError("Reason is Required");
      return;
    }
    setLoader(true);
    const postData = {
      userId: data?.id,
      userType: "serviceProvider",
      reason: message,
    };
    try {
      const response = await rejectServiceProviderApi(postData);
      toast.success("Service Provider Suspended Successfully");
      refresh();
      onHide();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{"Write a reason for Rejection"}</Modal.Title>
      </Modal.Header>
      <div className="p-4">
        <div className="form-group p-2">
          <textarea
            type="text"
            className="form-control"
            placeholder="Enter the reason"
            style={{ minHeight: "100px" }}
            onChange={(e) => {
              setMessage(e.target.value);
              setMessageError("");
            }}
          />
          <span className="text-danger">{messageError}</span>
        </div>
        <div className="pl-2">
          <button
            type="button"
            className="btn btn-sm btn-primary w-100"
            onClick={() => handleRejectVender()}
          >
            {loader ? <Spinner animation="border" size="sm" /> : "Done"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
