import { useCallback, useEffect, useState } from "react";
import { Modal, Spinner, Card } from "react-bootstrap";
import toast from "react-hot-toast";
import emptyImg from "../../images/defaultDp.jpg";
import {
  addManagerMember,
  getServiceProviderApi,
} from "../components/APIs/Api";

export default function JoinMemberModal({
  showModal,
  onHide,
  refresh,
  selectedData,
}) {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState([]);
  const [search, setSearch] = useState("");
  const [userList, setUsersList] = useState("");

  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 600),
    []
  );
  async function handleGroupUserList() {
    setLoader(true);
    try {
      const res = await getServiceProviderApi(0, 1000, search, false);
      setUsersList(res);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoader(false);
  }

  function joinCheckbox(id) {
    if (checkbox.includes(id)) {
      setCheckbox([]);
    } else {
      setCheckbox([id]);
    }
  }

  async function onHandleJoinGroup() {
    if (checkbox.length !== 1) {
      toast.error("Please select only one member to add.");
      return;
    }
    console.log(selectedData);
    setLoading(true);
    try {
      const res = await addManagerMember(checkbox[0], selectedData?._id);
      if (res?.status === 200) {
        toast.success("Member added successfully");
        refresh();
        onHide();
      } else {
        throw new Error("Failed to add member");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGroupUserList();
  }, [search]);

  useEffect(() => {
    handleGroupUserList();
  }, []);

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered className="group-modal">
        <Card>
          <span className="close" onClick={onHide}>
            &times;
          </span>

          <div
            className="hide-scrollbar"
            style={{
              overflowY: "auto",
              height: "66vh",
            }}
          >
            <div className="p-1">
              <input
                type="text"
                placeholder="Search..."
                className="p-2 search-member"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                width: "100%",
                padding: "0",
                opacity: "0.2",
              }}
            ></div>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                {userList?.data?.data?.users?.map((item) => (
                  <div
                    className="d-flex align-items-center p-2 justify-content-start group-content"
                    // style={{ opacity: item.isGroupJoined ? 0.4 : 1 }}
                    key={item._id}
                  >
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        onChange={() => joinCheckbox(item._id)}
                        checked={checkbox.includes(item._id)}
                        disabled={item?.isGroupJoined}
                      />
                      <span className="checkmark"></span>
                    </label>

                    <img
                      style={{ borderRadius: "20px", marginLeft: "10px" }}
                      height={35}
                      width={35}
                      src={`http://209.126.2.133:5000${item?.image} `}
                      onError={onImageError}
                      alt="user icon"
                    />
                    <span className="group-name">{item?.name}</span>
                  </div>
                ))}
                {userList?.data?.data?.users?.length === 0 && (
                  <div
                    className="justify-content-center d-flex align-items-center"
                    style={{ height: "50vh" }}
                  >
                    Sorry, Data Not Found!
                  </div>
                )}
              </>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center">
            {checkbox.length > 0 && (
              <button
                className={`join-button ${loading ? "disabled" : ""}`}
                disabled={loading}
                type="submit"
                style={
                  loading
                    ? { pointerEvents: "none", opacity: 0.5 }
                    : { pointerEvents: "auto" }
                }
                onClick={() => onHandleJoinGroup()}
              >
                {loading ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ gap: "5px" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </div>
                ) : (
                  "Join"
                )}
              </button>
            )}
          </div>
        </Card>
      </Modal>
    </div>
  );
}
