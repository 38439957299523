import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import useImageUpload from "../components/useImageUpload";

export default function AddCategoryModal({
  title,
  show,
  onHide,
  buttonTitle,
  api,
  refresh,
  successMessage,
  selectedCategory,
}) {
  const [name, setName] = useState(selectedCategory?.name || "");
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(selectedCategory?.icon || ""); // For previewing image
  const { uploadImage, uploading, progress, response, error } =
    useImageUpload();

  async function handleAddFormSubmit(event) {
    event.preventDefault();

    if (name.trim().length === 0) {
      setNameError("Name is required");
      return;
    }
    setLoader(true);

    try {
      let iconFilePath = selectedCategory?.icon || "";

      if (image) {
        const uploadResponse = await uploadImage(image);
        iconFilePath = uploadResponse?.filePath || iconFilePath;
      }

      const postData = {
        name,
        icon: iconFilePath,
      };

      if (title === "Update Service") {
        postData._id = selectedCategory?._id;
      }
      if (title === "Add Sub Service") {
        postData.serviceId = selectedCategory;
      }
      if (title === "Update Sub Service") {
        postData.subserviceId = selectedCategory._id;
      }

      const res = await api(postData);
      setLoader(false);
      refresh();
      onHide(false);
      toast.success(successMessage);
    } catch (e) {
      setLoader(false);
      console.error(e?.response);
      toast.error(e?.response?.data?.message || "An error occurred");
    }
  }

  return (
    <Modal className="sortBy" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <h3 className="text-center m-0">{title}</h3>
      </Modal.Header>

      <form onSubmit={handleAddFormSubmit}>
        <div className="p-3">
          <label className="text-black font-w500">Service Image</label>
          <div className="contact-name form-group">
            <input
              type="file"
              className="form-control"
              accept=".jpg,.png,.jpeg"
              required={buttonTitle !== "Update"}
              onChange={(e) => setImage(e.target.files[0])}
            />
            {error && <div className="text-danger">{error}</div>}
            {/* Image preview */}
            {preview && (
              <div className="mt-3">
                <img
                  src={`http://209.126.2.133:5000${preview}`}
                  alt="Selected"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </div>
            )}
          </div>
          <label className="text-black font-w500">Service Name</label>
          <div className="contact-name">
            <input
              type="text"
              className="form-control"
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              placeholder="Enter service name"
            />
            <span className="text-danger">{nameError}</span>
          </div>
        </div>

        <div className="p-3 d-flex justify-content-end">
          <button className="btn btn-sm btn-primary" disabled={loader}>
            {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
          </button>
        </div>
      </form>
    </Modal>
  );
}
